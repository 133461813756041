/*
This is where you write custom SASS
*/


section[id*="interiorBanner"]:before,
section.home-carousel.position-relative.bg-lighter-green:before  {
  content: '';
  position: absolute;
  top: 0;
  height: 5px;
  width: 100%;
  background: white;
}

section.page-content.financing-page section[style*="margin-left: -25vw"]{
  padding-left: 0px!important;
  padding-right: 0px !important;
  margin-left: -10px!important;
  margin-right: -10px!important;
}


header {
    [id*="_ControlText"] {
        letter-spacing: -0.04em;
    }
}
section.address-section {
    a {
        color: $white;
        &:hover {
            color: darken($white, 10%);
        }
    }
}

nav {
    @include media-breakpoint-down(md) {
        #main-menu {
          li {
              a {
                  &.current,&.highlighted {
                    color: #4f5d21!important;
                  }
              }
          }
        }
    }
}


section[id*="interiorBanner"] {
  .bg-col {
    @include media-breakpoint-down(sm) {
      min-height: 250px;
    }
  }
}

section.home-carousel {
  @include media-breakpoint-up(lg){ 
    .owl-nav {

      .owl-prev {
        left: -7%;
      }
      .owl-next {
        right: -7%;
      }
    }
  }

  .bg-col {
    min-height: 400px;
    @include media-breakpoint-down(sm) {
      min-height: 300px;
    }
  }
}

footer {
    ul {
        li {
            a {
                &.current {
                    color: map-get($theme-colors, lighter-green);
                    &:hover {
                        color: darken(map-get($theme-colors, lighter-green),10%);
                    }
                }
            }
        }
    }
}

section.contact-section {
    .contact-header-col {
        flex-direction: column;
        padding-right: 3px;
        @include media-breakpoint-down(sm) {
          & {
            padding-left: 6px !important;
            padding-right: 6px !important;
            padding-bottom: 6px !important;
          }
        }
    }
    .contact-col {
      padding-left: 3px;
    }
    [class*="form"] {
        width: 100%;
    }
    .form-group {
        margin-bottom: 7px !important;
        margin-top: 0 !important;
    }
    // @include media-breakpoint-down(md) {
    //     & {
    //         height: auto !important;
    //     }
    //     .contact-header-col {
    //         flex-direction: row;
    //     }
    //     .contact-col {
    //         background: $white !important;
    //     }
    // }
    // @include media-breakpoint-down(sm) {
    //     div.bg-div {
    //         height: 35vw !important;
    //     }
    //     div.tagline-div {
    //         height: auto !important;
    //     }
    //     .contact-header-col {
    //         flex-direction: column-reverse;
    //     }
    // }
}

.phone-dropdown-menu,
.address-dropdown-menu {
  transition: opacity 0.2s ease, visibility 0.2s ease, margin-top 0.2s ease;
  display: block;
  opacity: 0;
  visibility: hidden;
  padding: .5rem 0;
  border: none;
  background: transparent;
  margin-top: -1.5rem !important;
  max-width: 100% !important;
  min-width: 16rem !important;
  margin-right: -5px;
  box-shadow: none !important;
  text-transform: uppercase;

  .address-suite,
  .phone-number {
      font-size: $small-font-size;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    top: 5%;
    bottom: 25%;
    left: 25%;
    right: 25%;
    transition: 0.3s ease;
    border-radius: $border-radius;
    background-color: $white;
    box-shadow: $box-shadow-sm;
    opacity: 0;
  }

  &.show {
    opacity: 1;
    visibility: visible;

    &:before {
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      opacity: 1;
    }

    ul {
      @for $i from 1 to 10 {
        a:nth-child(#{$i}) {
          opacity: 1;
          visibility: visible;
          transform: translate3d(0, 0, 0);
          transition-delay: 0.2 + ($i * 0.025s);
        }
      }
    }
  }


  @media screen and (max-width: map-get($grid-breakpoints, md) - 1) {
    left: 50% !important;
    transform: translate3d(-50%, 0, 0) !important;
    top: 2rem !important;
    width: 90%;
    max-width: 300px !important;
  }

  ul {
    padding: 0px 5px;
    margin: 0;

    a {
      font-size: $font-size-base;
      line-height: 1.4em;
      transition: 0.3s ease;
      opacity: 0;
      visibility: hidden;
      transform: translate3d(0, 1rem, 0);
      color: #5a7229;
      display: block;
      &:hover {
          background: rgba(map-get($theme-colors, light-green),0.15);
      }
      span {
          color:map-get($theme-colors, browncolor)
      }

      .row {
        padding: 1rem * 0.5 1rem * 0.25 1rem * 0.5 1rem;
        position: relative;

        .font-weight-bold {
          font-weight: 400 !important;
        }

        &:before {
          content: '';
          display: block;
          position: absolute;
          z-index: -1;
          top: 5%;
          bottom: 5%;
          left: 5%;
          right: 5%;
          transition: 0.3s ease;
          border-radius: $border-radius;
          background-color: transparent !important;
        }
      }

      &:hover {

        .row {
          &:before {
            right: 0;
            left: 0;
            top: 0;
            bottom: 0;
            opacity: 1;
          }
        }
      }
    }
  }
}

